<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-card-title class="pl-0 pt-0">
                <b>Carga de gráficos</b>
              </v-card-title>
            </v-col>
          </v-row>
          <v-row>
            <!-- Meses a procesar -->
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="mesesAProcesar"
                label="Meses a procesar"
                outlined
                clearable
                dense
                :rules="rules.required.concat(rules.positiveNumberHigherThanCero, rules.maxNumber(mesesAProcesar, 24))"
              >
              </v-text-field>
            </v-col>
            <!-- Agrupacion geografica -->
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="agruGeograficaSelected"
                :items="agruGeografica"
                label="Agrupación geográfica"
                return-object
                item-text="value"
                item-value="id"
                outlined
                clearable
                :rules="rules.required"
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Agrupacion de practicas -->
            <v-col cols="6" class="py-0" v-if="isPrestaciones">
              <v-autocomplete
                v-model="agruPracticasSelected"
                :items="agruPracticas"
                label="Agrupación de prácticas"
                return-object
                item-text="value"
                item-value="id"
                outlined
                clearable
                :rules="rules.required"
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Agrupacion de edades -->
            <v-col
              cols="6"
              class="py-0"
              v-if="isPrestaciones || isAfiliaciones"
            >
              <v-autocomplete
                v-model="agruEdadesSelected"
                :items="agruEdades"
                label="Agrupación de edades"
                return-object
                item-text="value"
                item-value="id"
                outlined
                clearable
                :rules="rules.required"
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-card-title class="pl-0 pt-0">
                <b>Ejecución de recarga automática</b>
              </v-card-title>
            </v-col>
          </v-row>
          <!-- Seleccionar dias de la semana -->
          <v-row>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                class="mb-0 mt-0 pt-0"
                outlined
                clearable
                dense
                multiple
                v-model="diasSelected"
                label="Días de la semana"
                item-text="value"
                item-value="value"
                autocomplete="on"
                :rules="diasSelected.length == 0 ? ['Campo requerido'] : []"
                :items="diasItems"
                return-object
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="diasToggle()">
                    <v-list-item-action>
                      <v-icon :color="diasSelected.length > 0 ? 'primary' : ''">
                        {{ multiselectIconDias }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ diasSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- Horario de ejecución -->
            <v-col class="py-0">
              <v-menu
                ref="menuHoraEjecucion"
                v-model="menuHoraEjecucion"
                :close-on-content-click="false"
                :return-value.sync="timeEjecucion"
                transition="scale-transition"
                offset-y
                :nudge-bottom="0"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="timeEjecucion"
                    label="Horario de ejecución"
                    :append-icon="timeIcon"
                    outlined
                    dense
                    readonly
                    hint="Formato HH:MM"
                    @focus="(menuHoraEjecucion = true)"
                    @input="menuHoraEjecucion = false"
                    @blur="$refs.menuHoraEjecucion.save(timeEjecucion)"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  scrollable
                  no-title
                  v-if="menuHoraEjecucion"
                  :allowed-minutes="allowedStep"
                  v-model="timeEjecucion"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.menuHoraEjecucion.save(timeEjecucion)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6" class="py-0">
              <v-switch
                class="mt-1"
                v-model="habilitado"
                label="Habilitado"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
export default {
  name: "EditAnalyticsContainer",
  props: {
    tituloDeAnalytics: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    formEditTitle: ``,
    rules: rules,
    mSCloseBoxIcon: enums.icons.CLOSE_BOX,
    checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
    isFormValid: false,
    isIngresos: false,
    isPrestaciones: false,
    isAfiliaciones: false,
    mesesAProcesar: null,
    habilitado: false,
    agruGeograficaSelected: null,
    agruPracticasSelected: null,
    agruEdadesSelected: null,
    agruGeografica: [],
    agruPracticas: [],
    agruEdades: [],
    diasSelected: [],
    timeEjecucion: null,
    menuHoraEjecucion: false,
    timeIcon: enums.icons.TIME,
    diasItems: [
      { value: "Lunes" },
      { value: "Martes" },
      { value: "Miércoles" },
      { value: "Jueves" },
      { value: "Viernes" },
      { value: "Sábado" },
      { value: "Domingo" },
    ],
    resSaveParams: null,
    resSaveRecarga: null,
    programacionModuloData: null,
    dataRecargaAuto: null,
    moduloIdAfiliaciones: { moduloId: 1 },
    moduloIdPrestaciones: { moduloId: 2 },
    moduloIdIngresos: { moduloId: 3 },
  }),
  created() {
    this.formEditTitle = `Configuración de tablero: ${this.tituloDeAnalytics}`;
    this.setBanderas();
    this.setProgramacionBymodulo();
    this.setSelects();
    this.setAnalytics();
  },
  computed: {
    multiselectIconDias() {
      if (this.selectAllDias) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllDias() {
      return (
        this.diasSelected && this.diasSelected.length === this.diasItems.length
      );
    },
  },
  methods: {
    ...mapActions({
      getAgrupacionesPracticas: "analytics/getAgrupacionesPracticas",
      getAgrupacionGeografica: "analytics/getAgrupacionGeografica",
      getAgrupacionEdad: "analytics/getAgrupacionEdad",
      getParamsGralAfiliaciones: "analytics/getParamsGralAfiliaciones",
      getParamsGralPrestaciones: "analytics/getParamsGralPrestaciones",
      getParamsGralIngresos: "analytics/getParamsGralIngresos",
      saveParamsGralAfiliaciones: "analytics/saveParamsGralAfiliaciones",
      saveParamsGralPrestaciones: "analytics/saveParamsGralPrestaciones",
      saveParamsGralIngresos: "analytics/saveParamsGralIngresos",
      getProgramacionModulos: "analytics/getProgramacionModulos",
      saveProgramacionModulo: "analytics/saveProgramacionModulo",
      setAlert: "user/setAlert",
    }),
    allowedStep: (m) => m === 0 || m === 30,
    setBanderas() {
      switch (this.tituloDeAnalytics) {
        case "Afiliaciones":
          this.isAfiliaciones = true;
          break;
        case "Prestaciones":
          this.isPrestaciones = true;
          break;
        case "Ingresos":
          this.isIngresos = true;
          break;
        default:
          break;
      }
    },
    async setProgramacionBymodulo() {
      if (this.isAfiliaciones) {
        this.programacionModuloData = await this.getProgramacionModulos(
          this.moduloIdAfiliaciones
        );
      } else if (this.isPrestaciones) {
        this.programacionModuloData = await this.getProgramacionModulos(
          this.moduloIdPrestaciones
        );
      } else if (this.isIngresos) {
        this.programacionModuloData = await this.getProgramacionModulos(
          this.moduloIdIngresos
        );
      }
      this.timeEjecucion = this.programacionModuloData.hora;
      this.habilitado = this.programacionModuloData.habilitado;
      //conviertir sting de dias en un array para el seteo
      const diasString = this.programacionModuloData.dias;
      const arrayDiasConvertido = diasString
        .split(", ")
        .map((diasString) => diasString.replace(/'/g, ""))
        .map((diasString) => ({ value: diasString.trim() }));
      this.diasSelected = arrayDiasConvertido;
    },
    async setSelects() {
      if (this.isPrestaciones) {
        const agruPra = await this.getAgrupacionesPracticas(); 
        this.agruPracticas = agruPra;
      }
      if (this.isPrestaciones || this.isAfiliaciones) {
        const agrupaEdades = await this.getAgrupacionEdad();
        this.agruEdades = agrupaEdades;
      }
      const agrupaGeograficas = await this.getAgrupacionGeografica();
      this.agruGeografica = agrupaGeograficas;
    },
    async setAnalytics() {
      if (this.isAfiliaciones) {
        const response = await this.getParamsGralAfiliaciones();
        this.mesesAProcesar = response.reprocesarMeses;
        this.agruGeograficaSelected = response.agrupacionGeografica;
        this.agruEdadesSelected = response.agrupacionEdad;
      }
      if (this.isPrestaciones) {
        const response = await this.getParamsGralPrestaciones();
        this.mesesAProcesar = response.reprocesarMeses;
        this.agruGeograficaSelected = response.agrupacionGeografica;
        this.agruEdadesSelected = response.agrupacionEdad;
        this.agruPracticasSelected = response.agrupacionPracticas;
      }
      if (this.isIngresos) {
        const response = await this.getParamsGralIngresos();
        this.mesesAProcesar = response.reprocesarMeses;
        this.agruGeograficaSelected = response.agrupacionGeografica;
      }
    },
    async saveEdit() {
      this.isFormValid = false;
      //Datos para enviar/guardar la recarga automatica
      const stringDias = this.diasSelected.map((obj) => obj.value).join(", ");
      this.dataRecargaAuto = {
        programacionTableroId:
          this.programacionModuloData?.programacionTableroId,
        moduloId: this.programacionModuloData?.moduloId,
        dias: stringDias,
        hora: this.timeEjecucion,
        habilitado: this.habilitado,
      };
      try {
        //save datos para AFILIACIONES
        if (this.isAfiliaciones) {
          const afiliacionesData = {
            agrupacionEdad:
              this.agruEdadesSelected.id == undefined
                ? this.agruEdadesSelected
                : this.agruEdadesSelected.id,
            agrupacionGeografica:
              this.agruGeograficaSelected.id == undefined
                ? this.agruGeograficaSelected
                : this.agruGeograficaSelected.id,
            reprocesarMeses: this.mesesAProcesar
              ? parseInt(this.mesesAProcesar)
              : null,
          };
          this.resSaveRecarga = await this.saveProgramacionModulo(
            this.dataRecargaAuto
          );
          this.resSaveParams = await this.saveParamsGralAfiliaciones(
            afiliacionesData
          );
          //save datos para PRESTACIONES
        } else if (this.isPrestaciones) {
          const prestacionesData = {
            agrupacionEdad:
              this.agruEdadesSelected.id == undefined
                ? this.agruEdadesSelected
                : this.agruEdadesSelected.id,
            agrupacionGeografica:
              this.agruGeograficaSelected.id == undefined
                ? this.agruGeograficaSelected
                : this.agruGeograficaSelected.id,
            reprocesarMeses: this.mesesAProcesar
              ? parseInt(this.mesesAProcesar)
              : null,
            agrupacionPracticas:
              this.agruPracticasSelected.id == undefined
                ? this.agruPracticasSelected
                : this.agruPracticasSelected.id,
          };
          this.resSaveRecarga = await this.saveProgramacionModulo(
            this.dataRecargaAuto
          );
          this.resSaveParams = await this.saveParamsGralPrestaciones(
            prestacionesData
          );
          //save datos para INGRESOS
        } else if (this.isIngresos) {
          const ingresosData = {
            agrupacionGeografica:
              this.agruGeograficaSelected.id == undefined
                ? this.agruGeograficaSelected
                : this.agruGeograficaSelected.id,
            reprocesarMeses: this.mesesAProcesar
              ? parseInt(this.mesesAProcesar)
              : null,
          };
          this.resSaveRecarga = await this.saveProgramacionModulo(
            this.dataRecargaAuto
          );
          this.resSaveParams = await this.saveParamsGralIngresos(ingresosData);
        }
        if (
          this.resSaveParams.status === 200 &&
          this.resSaveRecarga.status === 200
        ) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = false;
      }
    },
    diasToggle() {
      this.$nextTick(() => {
        if (this.selectAllDias) this.diasSelected = [];
        else this.diasSelected = this.diasItems;
      });
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>